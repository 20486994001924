.spr-banner-img {
  position: relative;
  min-height: 288px;
  background-image: url('../../../public/img/mobile-hero.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 40px;
}
.spring-logo {
  position: absolute;
  bottom: 40px;
  margin: 0 auto;
  height: 32px;
  width: 160px;
}
